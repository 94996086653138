import http from "@/_helper/api-services";
import DatePicker from 'vue2-datepicker';

export default {
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(today);

        minDate.setMonth(minDate.getMonth() - 1);

        return {
            fields: [{
                    key: "codigo",
                    label: "Código",
                    sortable: true,
                },
                {
                    key: "codigoReferenciaBoleto",
                    label: "Boleto",
                    sortable: true,
                },
                {
                    key: "login",
                    label: "Login",
                    sortable: true,
                },
                {
                    key: "nome",
                    label: "Nome",
                    sortable: true,
                },
                {
                    key: "nomeProduto",
                    label: "Produto",
                    sortable: true,
                },
                {
                    key: "valorPedido",
                    label: "Valor",
                    sortable: true,
                },
                {
                    key: "metodoPagamento",
                    label: "Método de pagamento",
                    sortable: true,
                },
                {
                    key: "dataPedido",
                    label: "Data do pedido",
                    sortable: true,
                },
                {
                    key: "dataPagamento",
                    label: "Data do pagamento",
                    sortable: true,
                },
                {
                    key: "pago",
                    label: "Pago",
                    sortable: false,
                },
            ],
            nomeLogin: '',
            pedidos: [],
            login: null,
            nome: null,
            dataInicio: minDate,
            dataFim: today,
            somentePagos: Boolean(true),
            ordenacaoPor: "dataPagamento",
            sortBy: this.ordenacaoPor,
            sortDesc: true,
            options: [
                { value: null, text: "Ordenar por" },
                { value: "nome", text: "Nome" },
                { value: "login", text: "Login" },
                { value: "dataPedido", text: "Data do pedido" },
                { value: "dataPagamento", text: "Data do pagamento" },
                { value: "valorPedido", text: "Valor" },
            ],
        };
    },
    components: {
        DatePicker,
    },
    mounted() {
        //this.obterNomeLogado();
        this.buscarPedidos(false);
    },
    methods: {
        obterNomeLogado() {
            const logado = localStorage.getItem('user');
            this.dadoLogado = JSON.parse(logado);
            this.nomeLogin = this.dadoLogado.username.split(' ')[0];
        },
        buscarPedidos(closeCollapse) {
            this.$loading(true);

            var filtros = {
                login: this.login,
                nome: this.nome,
                dataInicio: this.dataInicio,
                dataFim: this.dataFim,
                somentePagos: Boolean(this.somentePagos),
                ordenarPor: this.ordenacaoPor,
            };

            this.sortBy = this.ordenacaoPor;

            http.post("/requests/listOrders", filtros).then((response) => {
                this.pedidos = response.data;
                this.$loading(false);

                if (closeCollapse)
                    this.$root.$emit("bv::toggle::collapse", "collapse-filtros");
            }, error => { this.$loading(false); });
        },
    },
};